import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { IconType } from 'react-icons';
import { MdArrowDropDown, MdCancel } from 'react-icons/md';
import { OptionItem, OptionValue } from '../types';
import MultipleLayerCheckbox from './MultipleLayerCheckbox';
import OptionCountLabel from './OptionCountLabel';

type MultiLayerFilterProps = {
  placeholder?: string;
  label?: string;
  icon?: ReactElement<IconType>;
  options: OptionItem[];
  values?: OptionValue[];
  onChange: (values?: OptionValue[]) => void;
  onOpenStatusChange?: (isOpen: boolean) => void;
  customSettingComponent?: ReactElement;
};

const MultiLayerFilter: FC<MultiLayerFilterProps> = (props: MultiLayerFilterProps) => {
  const { options, onChange, label, icon, values, onOpenStatusChange, customSettingComponent } =
    props;
  const { isMobile, isDesktop } = useScreenInfos();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const isActive = useMemo(() => {
    return values && values.length > 0;
  }, [values]);

  const resetFilter = () => {
    onChange(undefined);
  };

  useEffect(() => {
    if (onOpenStatusChange) {
      onOpenStatusChange(isFilterOpen);
    }
  }, [onOpenStatusChange, isFilterOpen]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this isFilterOpen necessary?
  useEffect(() => {
    const handleIsFilterOpen = () => {
      setIsFilterOpen(false);
    };
    window.addEventListener('popstate', handleIsFilterOpen);

    return () => {
      window.removeEventListener('popstate', handleIsFilterOpen);
    };
  }, [isFilterOpen]);

  return (
    // isLazyをfalseにすると、設備の選択のモーダルが一瞬表示されるので、isLazyをtrueにしている
    <Popover isOpen={isFilterOpen} onClose={() => setIsFilterOpen(false)}>
      <PopoverTrigger>
        <HStack
          border='1px'
          borderColor='neutral.300'
          borderRadius='md'
          w='fit-content'
          _hover={{
            bg: 'neutral.50',
            borderColor: 'neutral.300',
            color: ' neutral.800',
          }}
          spacing='0'
        >
          <Flex
            justifyContent='center'
            alignItems='center'
            sx={
              isActive
                ? {
                    bg: 'primary.100',
                    borderColor: 'gray.300',
                  }
                : undefined
            }
          >
            <Button
              pl={3}
              size='sm'
              variant='unstyled'
              color={isActive ? 'primary.500' : 'neutral.800'}
              _hover={{ color: isActive ? 'primary.500' : 'neutral.800' }}
              fontSize='sm'
              fontWeight='semibold'
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              {icon ? icon : label}
            </Button>
            <OptionCountLabel optionCount={values?.length} />
            <IconButton
              aria-label='Reset Filter'
              size='ms'
              mx={0.5}
              variant='outline'
              color={isActive ? 'primary.500' : 'neutral.500'}
              border={0}
              _hover={{ background: 'transparent' }}
              bg='transparent'
              _active={{}}
              onClick={isActive ? resetFilter : () => setIsFilterOpen(!isFilterOpen)}
              icon={isActive ? <MdCancel /> : <MdArrowDropDown />}
            />
          </Flex>
        </HStack>
      </PopoverTrigger>

      {isDesktop && (
        <PopoverContent
          p={0}
          m={0}
          ml={4}
          boxShadow='0'
          borderColor='transparent'
          bg='transparent'
          zIndex={100}
        >
          <PopoverBody p={0} m={0} boxShadow='0' bg='transparent'>
            <MultipleLayerCheckbox
              options={options}
              onChange={onChange}
              values={values}
              isDefaultOpen
              onCloseFilter={() => setIsFilterOpen(false)}
              customSettingComponent={customSettingComponent}
            />
          </PopoverBody>
        </PopoverContent>
      )}
      {isMobile && isFilterOpen && (
        <MultipleLayerCheckbox
          icon={icon}
          label={label}
          options={options}
          onChange={onChange}
          values={values}
          isDefaultOpen
          onCloseFilter={() => setIsFilterOpen(false)}
          customSettingComponent={customSettingComponent}
        />
      )}
    </Popover>
  );
};

export default MultiLayerFilter;
